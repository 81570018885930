import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { atraparInicioSesion } from "../../utils/functions";

const LoginUrl = () => {
  useEffect(() => {
    atraparInicioSesion(typeof window !== "undefined" && window.location.href);
  }, []);
  return <CircularProgress size={92} color="primary" />;
};

export default LoginUrl;
